import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back to `}<a parentName="p" {...{
        "href": "/Case-studies",
        "title": "Case-studies"
      }}>{`Case-studies`}</a>{``}</p>
    <h3>{`DEMOS pairs Polis with nationwide representative sampling`}</h3>
    <p><strong parentName="p">{`Quoting the DEMOS report:`}</strong></p>
    <blockquote>
      <p parentName="blockquote">{`Significantly, this is the first time the tool has been paired with nationally representative sampling. This allows responses to be reflective of the public at large, including marginalised groups, rather than a self-selecting sample. This will ensure policies will benefit from the ideas and experiences of groups who would otherwise be unlikely to take part in the process, and make the findings more persuasive to policymakers and politicians.`}</p>
    </blockquote>
    <h3>{`Links`}</h3>
    <p>{`Website: `}<a parentName="p" {...{
        "href": "https://demos.co.uk/project/polis-and-the-political-process/"
      }}>{`https://demos.co.uk/project/polis-and-the-political-process/`}</a>{`
Report link: `}<a parentName="p" {...{
        "href": "https://demos.co.uk/wp-content/uploads/2020/08/Polis-the-Political-Process-NEW.pdf"
      }}>{`https://demos.co.uk/wp-content/uploads/2020/08/Polis-the-Political-Process-NEW.pdf`}</a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/harrydcarr/status/1290226393868054529"
        }}>{`https://twitter.com/harrydcarr/status/1290226393868054529`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://demos.co.uk/project/the-future-of-towns/"
        }}>{`https://demos.co.uk/project/the-future-of-towns/`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      